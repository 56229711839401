a {
  text-decoration: none;
  cursor: pointer;
}


a.nav-link{
  display: flex;
  align-items: "center";
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  color: #1b3f56;
  transition: all .3s;
}

a.active-nav-link {
  color: #077742;
}
a.mobile-nav-link {
  color: #1b3f56
}
