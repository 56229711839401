.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* height: 280px; */
  

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

/* body {
  background: #000;
  color: #000;
} */

.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center center;
}

.mySwiper2 {
  max-height: 300px;
  width: 100%;
  max-width: 100%;
}


.image-viewer-slider {
  height: 280px;
}

.mySwiperTop {
  display: flex;
  min-height: 500px;
  height: calc(100vh - 80px);
  max-width: 100vw;
  width: 100%;
  object-fit: cover;
  object-position: "bottom center";
}

.mySwiper {
  max-height: 120px;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
  display: flex;
  align-items: center;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  padding: 5px;
  border-radius: 4px;
  background-color: #077742;
}

.swiper-button-next:after {
  content: ">";
}

.swiper-button-prev:after {
  content: "<";
}
