a.footer-nav-link{
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  transition: all .3s;
}

a.footer-nav-link-active {
  color: #077742;
}