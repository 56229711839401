@keyframes glowing {
  0% {
    background-color: #25d266;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: #25d266;
  }
}
