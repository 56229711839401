.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}

.TopContentWraper {
  width: "100%";
  min-height: "100vh";
  background-size: "cover";
  background-position: "center center";
}

.TopContentGradient {
  width: "100%";
  min-height: "100vh";
  background: "linear-gradient(133deg, #323232, transparent)";
  padding-bottom: "10px";
}

.TopContentContainer {
  padding-top: "100px",
}